import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import Save from '@mui/icons-material/Save';

const EditAndSaveButton = ({
  saveInfo,
  disabledEdit,
  toggleDisabledEdit,
  disabledSave,
}) => {
  const getIcon = () => {
    if (disabledEdit) {
      return <Edit fontSize="small" color="primary" />;
    }
    if (disabledSave) {
      return <Close fontSize="small" color="primary" />;
    }
    return <Save fontSize="small" color="primary" />;
  };
  const handleOnClick = () => {
    toggleDisabledEdit();
    if (!disabledEdit && !disabledSave) {
      saveInfo();
    }
  };
  return (
    <IconButton
      size="small"
      onClick={handleOnClick}
    >
      {getIcon()}
    </IconButton>
  );
};

EditAndSaveButton.propTypes = {
  saveInfo: PropTypes.func.isRequired,
  disabledEdit: PropTypes.bool.isRequired,
  toggleDisabledEdit: PropTypes.func.isRequired,
  disabledSave: PropTypes.bool.isRequired,
};

export default EditAndSaveButton;
