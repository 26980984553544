import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from '@mui/material';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import { openShoppingCart } from '../../helpers';
import NavbarButton from './NavbarButton';
import { useShoppingCart } from '../../hooks';

const ShoppingCartIconButton = ({ cartType }) => {
  const { shoppingCartData, disabledShowCart } = useShoppingCart({ cartType });
  return (
    <NavbarButton
      onClick={() => openShoppingCart(cartType)}
      disabled={disabledShowCart}
    >
      <Badge
        badgeContent={shoppingCartData?.length}
        color="primary"
        sx={{ p: 0.5 }}
      >
        <ShoppingCart />
      </Badge>
    </NavbarButton>
  );
};

ShoppingCartIconButton.propTypes = {
  cartType: PropTypes.string.isRequired,
};

export default ShoppingCartIconButton;
