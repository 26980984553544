import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@mui/material';

const CenteredCard = ({ children }) => (
  <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{children}</Card>
);

CenteredCard.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CenteredCard;
